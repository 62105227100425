/**
 * Theme for Virgin AU site
 **/
import type { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types.d';
import { buildThemeMixinsAPACLW, buildThemeWidgetStylesAPACLW, buildThemeVarsAPACLW } from 'dw-apac-law/theme';
import { montserratFont, gothamFont } from '../common/nextFont';

export const buildThemeVarsAPACVir = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const virCherryPie = '#2D054E';
  const virRed500 = '#E11931'; //color4
  const virRed600 = '#BB0C29'; //color5
  const virRed700 = '#FBE6F1'; //color6
  const virPurple100 = '#F0EAF3'; //color7
  const virPurple500 = '#6A288A'; //color9
  const virPurple700 = '#4A1C61'; //color10
  const virPurple800 = '#370A4C'; //color11
  const virPinkOfferPill = '#E11931';
  //secondary
  const virCream300 = '#F8F5EF'; //color12
  const virCream500 = '#EFECE8'; //color13
  const virWhite = '#FFFFFF'; //color14
  const virGrey200 = '#EDEDED'; //color16
  const virGrey400 = '#BCBCBC'; //color17
  const virGrey600 = '#767676'; //color18
  const virGrey700 = '#616161'; //color19
  const virGrey800 = '#494949'; //color20
  const virBlack = '#222222'; //color21
  const virSecondaryDark = '#112B4B';
  const virSvgCheckboxBg = '%234A1C61';

  //accent-purple
  const virLavender = '#DCD4EA'; //color22
  const virLilac = '#977DC1'; //color23
  //accent-Green
  const virGreen = '#35B63E'; //color24
  const virTeal = '#469D8E'; //color25
  //accent-Gold
  const virGold = '#DFB158'; //color26
  const lwPink = '#CF004F';
  const virRed = '#D62021';

  const virOceanGreen = '#009F8E';

  const montserrat = montserratFont.style.fontFamily;
  const gotham = gothamFont.style.fontFamily;

  const lwThemeVars = buildThemeVarsAPACLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'Virgin AU',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-white.svg`,
    },
    colors: {
      primary1: virRed500,
      primary2: virPurple500,
      primary3: virCream300,
      primary4: virRed500,
      primary5: virRed700,
      primary6: lwPink,
      primary7: virPurple100,
      primary8: virBlack,
      primary9: virPurple500,
      primary10: virPurple700,
      primary11: virPurple800,
      secondary1: virCream300,
      secondary2: virCream500,
      secondary3: virWhite,
      secondary4: virSecondaryDark,
      secondary5: virGrey200,
      secondary6: virGrey400,
      secondary7: virGrey600,
      secondary8: virGrey700,
      secondary9: virGrey800,
      secondary10: virBlack,
      secondary11: virOceanGreen,
      accentLavender: virLavender,
      accentLilac: virLilac,
      accentGreen: virGreen,
      accentTeal: virTeal,
      accentGold: virGold,
      accentThunderbird500: virRed,
      textPrimaryBg: virRed500,
      pinkOfferPillBg: virPinkOfferPill,
      svgCheckboxBg: virSvgCheckboxBg,
      //specific colour definitions
      btnsPrimaryBg: virRed500,
      btnsPrimaryColor: vars.colors.white,
      btnsPrimaryHoverBg: virRed600,
      btnsPrimaryHoverColor: vars.colors.white,
      btnsSecondaryBg: vars.colors.white,
      btnsSecondaryColor: vars.colors.black,
      btnsSecondaryHoverBg: virRed600,
      btnsSecondaryHoverColor: vars.colors.white,
      btnsTertiaryBg: vars.colors.white,
      btnsTertiaryColor: vars.colors.black,
      btnsTertiaryBorder: vars.colors.white,
      btnsTertiaryHoverBg: vars.colors.white,
      btnsTertiaryHoverColor: vars.colors.black,
      savingsTextColor: virRed500,

      footerPrimaryBg: virCherryPie,
      footerdrinkaware: vars.colors.white,

      bgColors: {
        virRed: virRed500,
        virPurple: virPurple500,
        virCream: virCream500,
        virWhite,
        virBlack,
      },
    },
    fonts: {
      familyPrimary: montserrat,
      familySecondary: lwThemeVars.fonts.roboto,
      familyTertiary: montserrat,
      familyTeritary: gotham,
      headerMainNavTitle: montserrat,
      headerMainNavSubTitle: montserrat,
      weightLight: '300',
      weightNormal: '400',
      weightMedium: '500',
      weightMediumPlus: '600',
      weightBold: '700',
    },
  });
};

export const buildThemeMixinsAPACVir = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const buildThemeWidget = buildThemeMixinsAPACLW(vars);

  return merge({}, buildThemeWidget, {
    hxl: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize2};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize60};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h1: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize36};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize48};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h2: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize32};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize40};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h3: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize28};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize36};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h4: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize24};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    h5: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize22};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    h6: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight115};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize24};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight115};
      }
    `,
    h7: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize18};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize22};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h8: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    eyebrowRegular: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize14};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    eyebrowSmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    utility: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing9};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
        line-height: ${vars.fonts.fontLineHeight100};
        letter-spacing: ${vars.fonts.fontLetterSpacing8};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    quote: `
      font-family: ${vars.fonts.familyTeritary};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.weightLight};
      font-style: ${vars.fonts.styleItalic};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize28};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    bodySmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    caption: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
  });
};

export const buildThemeWidgetStylesAPACVir = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesAPACLW(vars);

  return merge({}, buildThemeWidget, {
    GlobalHeader: {
      headerLogo: `
      img {
        width: 32px;
        height: 28px;
        ${vars.breakpoints.sm} {
          width: 40px;
          height: 35px;
        }
        ${vars.breakpoints.lg} {
          width: 76px;
          height: 65px;
        }
      }`,
    },
    Search: {
      hitWrapper: ` 
      ${buildThemeWidget.Search.hitWrapper}
      &.grid-view {
        .ais-Hits-list {
          .ais-Hits-item {
          .hit-content {
            .title {
              font-family: ${vars.fonts.familyPrimary};
            }
          }
          .addToCart-container { 
            .price-section {
              .sku-price {
                .vpp-price-section {
                  font-family: ${vars.fonts.familySecondary};
                }
              }
              .member-price-section-case {
                font-size: ${vars.fonts.sizeDefault};
              }
            }
          }
        }
      }
      `,
    },
    Product: {
      wrapper: `  
        ${buildThemeWidget.Product.wrapper}
        .layout-name {
          .sub-title {
            font-family: ${vars.fonts.familySecondary};
          }
        }
      `,
      orderform: `
        ${buildThemeWidget.Product.orderform}
        .top-price-section {
          .price-section {
            .case-sku-savings-sales-price {
              font-family: ${vars.fonts.familyPrimary} !important;
            }
          }
        }
        .straight-sku-container {
          .vpp-price {
            .price {
              font-family: ${vars.fonts.familyPrimary} !important;
            }
          }
        }
        .top-price-section {
          .price-section-sku {
            .price {
                font-family: ${vars.fonts.familyPrimary} !important;
            }
          }
        }
      `,
      learnMoreWrapper: `
      ${buildThemeWidget.Product.learnMoreWrapper}
      .modalTitle {
        font-family: ${vars.fonts.oswald} !important;
        font-size: 28px !important;
      }
      .modalTitle2 {
        font-family: ${vars.fonts.noto} !important;
      }
    `,
    },
    Footer: {
      default: `
      ${buildThemeWidget.Footer.default}
      font-family: ${vars.fonts.familySecondary};
      p {
        font-family: ${vars.fonts.familySecondary};
      }
      @media print {
        display: none;
      }
      .footer-bottom-section, .social-connections {
        background: ${vars.colors.footerPrimaryBg};
        box-shadow: 0 0 0 100vmax ${vars.colors.footerPrimaryBg};
        clip-path: inset(0 -100vmax);
        color: ${vars.colors.white};
        .wrapper {
          .copyrights {
            p {
              color: ${vars.colors.white};
              a {
                color: ${vars.colors.white};
              }
            }
          }
          .brand img {
              max-width: 6.5em;
          }
          ${vars.breakpoints.lg} {
            .bedrinkaware-group {
              justify-content: flex-start;
            }
          }
        }
      }
      .footer-nav-links {
        background: ${vars.colors.secondary1};
        box-shadow: 0 0 0 100vmax ${vars.colors.secondary1};
      }
      .social-connections {
        .wrapper {
          .social-connection-heading p {
            font-family: ${vars.fonts.familySecondary};
            letter-spacing: 2px;
            color: ${vars.colors.white};
          }
          a {
            color: ${vars.colors.white};
          }
        }
      }
      `,
      navItems: `
        ${buildThemeWidget.Footer.navItems}
        > div:first-of-type p{
          text-transform: uppercase;
          font-weight: bold;
        }
  `,
    },
    Cart: {
      orderSummaryContainer: `
        ${buildThemeWidget.Cart.orderSummaryContainer}
        .cart-head {
          h2 {
              font-weight: ${vars.fonts.weightMediumPlus} !important;
          }
        }

        .cart-area .empty-basket  h3 {
          font-weight: ${vars.fonts.weightMediumPlus};
        }
      `,
      MiniCart: `
      ${buildThemeWidget.Cart.MiniCart}
      .mini-cart-header {
        h3{
          font-weight: ${vars.fonts.weightMediumPlus};
        }
      }

      .price-section {
        ${vars.breakpoints.md}{ 
          flex-flow: column;
          align-items: baseline;
        }
      }

      .price-section .strike+.sale-price {
        color: ${vars.colors.accentThunderbird500}
      }

      .price-section .strike+.member-price {
        color:${vars.colors.secondary11};
      }

      .member-savings {
        color: ${vars.colors.secondary11};
      }
      `,
      cartDetailSection: `
        ${buildThemeWidget.Cart.cartDetailSection}
        .product-link {
          font-weight: ${vars.fonts.weightMediumPlus};
        }
      `,
      cartLayout: {
        cartCounterAndPrice: `
        ${buildThemeWidget.Cart.cartLayout.cartCounterAndPrice}
          .price-savings h6 {
            font-weight: ${vars.fonts.weightMediumPlus};
          }
        `,
      },
      EDWrapper: `
       ${buildThemeWidget.Cart.EDWrapper}
        .wrapper-container h2 , .order-total {
          font-weight: ${vars.fonts.weightMediumPlus};
        }
      `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsAPACVir, buildThemeMixinsAPACVir, buildThemeWidgetStylesAPACVir);

export default createTheme;
